import { sendLGMRequest } from "../../../app/utils/sendRequest";
import * as globals from "../../../globals";
import { LGMQuoteUserInputs } from "@agdeveloper/domain";

const sendGetMostRecentDateSecureLGMRequest = async () => {
  const apifunc = "getmostrecentdatesecurelgm";
  const args = {
    domain_object: globals.use_domain_objects_lgm,
  };

  return sendLGMRequest(apifunc, args);
};
const sendGetInitalExpectedGrossMarginsRequest = async (data: any) => {
  const apifunc = "getinitallgmexpectedgrossmargins";

  const args = {
    sales_effective_date: `${data.sales_effective_date.value
      .getFullYear()
      .toLocaleString("en", {
        minimumIntegerDigits: 4,
        useGrouping: false,
      })}-${(data.sales_effective_date.value.getMonth() + 1).toLocaleString(
      "en",
      {
        minimumIntegerDigits: 2,
        useGrouping: false,
      },
    )}-${data.sales_effective_date.value.getDate().toLocaleString("en", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}`,
    state_name: data["state_name"].name,
  };

  return sendLGMRequest(apifunc, args);
};
const sendGetMonthsToShowRequest = async () => {
  const apifunc = "getcurrentmonthslgm";
  const args = {
    domain_object: globals.use_domain_objects_lgm,
  };

  return sendLGMRequest(apifunc, args);
};
const sendLGMQuoteRequest = async (payload: LGMQuoteUserInputs) => {
  const apifunc = "getlgmquoteauth";
  // const args = {
  //   ...payload,
  //   return_domain_object: globals.use_domain_objects_lgm,

  // };
  const args = {
    lgm_quote_params: payload.to_json(),
    return_domain_object: globals.use_domain_objects_lgm,
    pass_domain_object: globals.use_domain_objects_lgm,
  };
  return sendLGMRequest(apifunc, args);
};
const sendLGMDTEChartDataRequest = async (payload: any) => {
  const apifunc = "getLGMDTEChartData";
  const args = payload.to_json();

  return sendLGMRequest(apifunc, args);
};

const lgmService = {
  sendGetMostRecentDateSecureLGMRequest,
  sendGetMonthsToShowRequest,
  sendLGMQuoteRequest,
  sendGetInitalExpectedGrossMarginsRequest,
  sendLGMDTEChartDataRequest,
  // sendDRPQuoteRequest,
  // sendDownloadDRPQuoteRequest,
  // sendYieldsRequest,
  // sendComponentPriceRestrictedValuesRequest,
};

export default lgmService;
