// Hook to check if user is scoped for passed scope
// import { useSelector } from "react-redux";
// import { RootState } from "../app/store";
//

import { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
// import { getPermissions } from "../../features/auth/authActions";

export default function useScoped(scope: string) {
  const [isScoped, setIsScoped] = useState(false);
  const { scopes } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (scopes) {
      setIsScoped(scopes.includes(scope));
    }
  }, [scopes]);

  return isScoped;
}
