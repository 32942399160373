import { LGMCommodityType, LGMQuoteType } from "@agdeveloper/domain";

export class DTEChartParams {
  private policy: LGMCommodityType;
  type: LGMQuoteType | "MIN_FEED" | "DEFAULT_FEED"; // MIN_FEED and DEFAULT_FEED if commodity type is dairy cattle
  private month: number;
  constructor({
    policy,
    type,
    month,
  }: {
    policy: LGMCommodityType;
    type: LGMQuoteType | "MIN_FEED" | "DEFAULT_FEED";
    month: number;
  }) {
    this.policy = policy;
    this.type = type;
    this.month = month;
  }
  to_json(): object {
    return {
      policy: this.policy.value,
      type: this.type instanceof LGMQuoteType ? this.type.value : this.type,
      month: this.month,
    };
  }
}
