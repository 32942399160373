export class DTEChartSeries {
  policy: string;
  quote: string;
  year: string;
  month: number;
  values: any[];

  constructor(
    policy: string,
    quote: string,
    year: string,
    month: number,
    values: number[],
  ) {
    this.policy = policy;
    this.quote = quote;
    this.year = year; // Convert year to a JavaScript Date object
    this.month = month;
    this.values = values.map((num) => Number(num.toFixed(2)));
  }

  // Getter and Setter for title
  getPolicy() {
    return this.policy;
  }

  setPolicy(val) {
    this.policy = val;
  }

  // Getter and Setter for type
  getQuoteType() {
    return this.quote;
  }

  setQuoteType(quote) {
    this.quote = quote;
  }

  // Getter and Setter for year
  getYear() {
    return this.year;
  }
  getMonth() {
    return this.month;
  }

  setYear(year) {
    this.year = year; // Ensure it's a Date object
  }

  // Getter and Setter for values
  getValues() {
    return this.values;
  }

  setValues(values) {
    this.values = values.map((num) => Number(num.toFixed(2)));
  }
}
