import React, { useState } from "react";
import MonthSelector from "./MonthSelector";
import LGMDTEPolicySelector from "./LGMDTEPolicySelector";
import LGMDTETypeSelector from "./LGMDTETypeSelector";
import LGMDTEChart from "./LGMDTEChart";

const LGMDTEChartHome = () => {
  const [monthIdx, setmonthIdx] = useState(0);
  const handleMonthChange = (monthIndex: number) => {
    setmonthIdx(monthIndex);
  };
  return (
    <>
      <div className="col my-1" style={{ minWidth: 300 }}>
        <LGMDTEPolicySelector />
      </div>
      <div className="col my-1" style={{ minWidth: 300 }}>
        <LGMDTETypeSelector />
      </div>
      <div className="col my-1" style={{ minWidth: 300 }}>
        <MonthSelector onMonthChange={handleMonthChange} />
      </div>
      <div className="col my-1" style={{ minWidth: 300 }}>
        <LGMDTEChart monthIdx={monthIdx} />
      </div>
    </>
  );
};

export default LGMDTEChartHome;
