import React, { useState } from "react";

const MonthSelector = ({ onMonthChange }) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [selectedMonth, setSelectedMonth] = useState(0);

  const handleChange = (event: { target: { value: string } }) => {
    const monthIndex = parseInt(event.target.value, 10);
    setSelectedMonth(monthIndex);
    if (onMonthChange) onMonthChange(monthIndex); // Pass the selected month to a parent component
  };

  return (
    <div className="card w-100">
      <div className="card-body">
      <label htmlFor="month-select" style={styles.label}>
        Select Month:
      </label>
      <select
        id="month-select"
        value={selectedMonth}
        onChange={handleChange}
        style={styles.select}
      >
        {months.map((month, index) => (
          <option key={index} value={index}>
            {month}
          </option>
        ))}
      </select>
    </div>
    </div>
  );
};

const styles = {
  label: {
    marginBottom: "10px",
    fontSize: "16px",
    fontWeight: "bold",
  },
  select: {
    marginLeft: 10,
    padding: "8px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
};

export default MonthSelector;
