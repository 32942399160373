import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { setLGMPolicy, setLGMType } from "../features/LGMSlice";
import { LGMQuoteType, LGMCommodityType } from "@agdeveloper/domain";

const defaultPolicySelection = {
  [LGMCommodityType.CATTLE]: false,
  [LGMCommodityType.SWINE]: false,
  [LGMCommodityType.DAIRY_CATTLE]: false,
};
const defaultTypePolicyMapping = {
  [LGMCommodityType.CATTLE]: LGMQuoteType.YEARLING_FINISHING,
  [LGMCommodityType.SWINE]: LGMQuoteType.FINISHING,
  [LGMCommodityType.DAIRY_CATTLE]: LGMQuoteType.NO_TYPE_SPECIFIED,
};

const LGMPolicySelector = () => {
  const dispatch = useAppDispatch();
  const { policy } = useAppSelector((state) => state.lgm.input);
  const [selectedPolicy, setSelectedPolicy] = useState(defaultPolicySelection);
  const [policyName, setPolicyName] = useState(policy.value);

  const updatePolicy = (policy_name) => {
    setSelectedPolicy({
      ...defaultPolicySelection,
      [policy_name]: true,
    });
    setPolicyName(policy_name);
  };

  const changePolicy = (e) => {
    updatePolicy(String(e.target.name));
  };

  useEffect(() => {
    updatePolicy(policy.value);
  }, []);

  useEffect(() => {
    dispatch(setLGMPolicy(new LGMCommodityType(policyName)));
    dispatch(
      setLGMType(new LGMQuoteType(defaultTypePolicyMapping[policyName])),
    );
  }, [policyName, dispatch]);

  return (
    <div className="card w-100">
      <div className="card-body">
        <h5 className="card-title">Policy Selection</h5>
        <p className="card-text">Please select your livestock type.</p>
        <form>
          <div className="form-group">
            <div>
              <input
                type="radio"
                style={{ marginRight: 10 }}
                id={LGMCommodityType.CATTLE}
                name={LGMCommodityType.CATTLE}
                checked={selectedPolicy[LGMCommodityType.CATTLE]}
                onChange={(e) => changePolicy(e)}
              />
              <label
                htmlFor={LGMCommodityType.CATTLE}
                className="form-check-label"
              >
                Cattle
              </label>
            </div>
            <div>
              <input
                type="radio"
                style={{ marginRight: 10 }}
                id={LGMCommodityType.SWINE}
                name={LGMCommodityType.SWINE}
                checked={selectedPolicy[LGMCommodityType.SWINE]}
                onChange={(e) => changePolicy(e)}
              />
              <label
                htmlFor={LGMCommodityType.SWINE}
                className="form-check-label"
              >
                Swine
              </label>
            </div>
            <div>
              <input
                type="radio"
                style={{ marginRight: 10 }}
                id={LGMCommodityType.DAIRY_CATTLE}
                name={LGMCommodityType.DAIRY_CATTLE}
                checked={selectedPolicy[LGMCommodityType.DAIRY_CATTLE]}
                onChange={(e) => changePolicy(e)}
              />
              <label
                htmlFor={LGMCommodityType.DAIRY_CATTLE}
                className="form-check-label"
              >
                Dairy Cattle
              </label>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LGMPolicySelector;
