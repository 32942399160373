import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/redux/hooks";
import { setLGMType } from "../../features/LGMSlice";
import { LGMQuoteType, LGMCommodityType } from "@agdeveloper/domain";

const policyVsTypeMapping = {
  [LGMCommodityType.CATTLE]: [
    LGMQuoteType.YEARLING_FINISHING,
    LGMQuoteType.CALF_FINISHING,
  ],
  [LGMCommodityType.SWINE]: [
    LGMQuoteType.FARROW_TO_FINISH,
    LGMQuoteType.FINISHING,
    LGMQuoteType.SEW_PIG_FINISHING,
  ],
  [LGMCommodityType.DAIRY_CATTLE]: ["MIN_FEED", "DEFAULT_FEED"],
};
function toTitleCase(str: String) {
  return str
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}
const LGMDTETypeSelector = () => {
  const dispatch = useAppDispatch();

  const { policy, type } = useAppSelector((state) => state.lgm.input);

  useEffect(() => {
    policy.value === LGMCommodityType.DAIRY_CATTLE &&
      dispatch(setLGMType("MIN_FEED"));
  }, [policy]);

  const updateType = (type_name) => {
    if (type_name === type.value || type_name === type) {
      return;
    }
    if (type_name === "MIN_FEED" || type_name === "DEFAULT_FEED") {
      dispatch(setLGMType(type_name));
      return;
    }
    dispatch(setLGMType(new LGMQuoteType(type_name)));
  };

  const changeType = (e) => {
    updateType(e.target.value);
  };

  return (
    <>
      <div className="card w-100">
        <div className="card-body">
          <label
            htmlFor="type-select"
            className="form-check-label"
            style={styles.label}
          >
            Select Type:
          </label>
          <select
            id="type-select"
            value={type.value}
            onChange={changeType}
            style={styles.select}
          >
            {policyVsTypeMapping[policy.value]?.map((typeKey) => (
              <option key={typeKey} value={typeKey}>
                {typeKey && toTitleCase(typeKey)}{" "}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};
const styles = {
  label: {
    marginBottom: "10px",
    fontSize: "16px",
    fontWeight: "bold",
  },
  select: {
    marginLeft: 10,
    padding: "8px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
};

export default LGMDTETypeSelector;
