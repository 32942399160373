import React, { useEffect, useState, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/redux/hooks";
import Plot from "react-plotly.js";
import { resetDTEChart } from "../../features/LGMSlice";
import { getLGMDTEChartData } from "../../features/LGMActions";
import { DTEChartParams } from "../../../LGMDTEObjects/DTEChartParams";
import { LGMQuoteType, LGMCommodityType } from "@agdeveloper/domain";

function toTitleCase(str: String) {
  return str
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join("-");
}

const LGMDTEChart = ({ monthIdx }: { monthIdx: number }) => {
  const dispatch = useAppDispatch();
  const [visibilityStates, setVisibilityStates] = useState<boolean[]>([]);
  const DTEChartData = useAppSelector(
    (state) => state.lgm.LGMDTEChart.chart_data,
  );
  const { isLoading, isError } = useAppSelector(
    (state) => state.lgm.LGMDTEChart,
  );
  const { policy, type } = useAppSelector((state) => state.lgm.input);

  const _dte_chart_params = useMemo(() => {
    const chartType =
      type.value === LGMQuoteType.NO_TYPE_SPECIFIED ? "MIN_FEED" : type;
    return new DTEChartParams({
      policy: policy,
      type: chartType,
      month: monthIdx,
    });
  }, [policy, type, monthIdx]);

  const colors = DTEChartData?.getChartColors();
  const sortedColumns = DTEChartData?.getDTESeries();

  useEffect(() => {
    if (type.value !== LGMQuoteType.NO_TYPE_SPECIFIED) {
      dispatch(resetDTEChart());
      dispatch(getLGMDTEChartData(_dte_chart_params));
    }
  }, [_dte_chart_params, dispatch]);

  useEffect(() => {
    if (sortedColumns?.length) {
      setVisibilityStates(Array(sortedColumns.length).fill(true)); // Ensure all lines are visible initially
    }
  }, [sortedColumns]); // Sync state when data updates

  const selectAllLines = () => {
    setVisibilityStates(Array(sortedColumns.length).fill(true));
  };
  const deSelectAllLines = () => {
    setVisibilityStates(Array(sortedColumns.length).fill(false));
  };
  const isAllSelected = visibilityStates.every((v) => v);
  const xAxisValues = [250, 200, 150, 100, 50, 0];
  // const xAxisText = xAxisValues.toReversed();

  const plotData = sortedColumns?.map((col, index) => ({
    // x: col.values.map((_, idx) => idx + 1), // Use index + 1 as x-axis
    x: col.values.map((_, idx) => 250 - idx),
    y: col.getValues(),
    visible: visibilityStates[index] ? "visible" : "legendonly",
    name: col.getYear(),
    type: "scatter",
    mode: "lines", // +markers", // Line and markers
    line: {
      color: colors[index], // Line color matches marker
      width: Math.max(3 - 0.2 * index, 1), // Line width decreases with index
    },
  }));

  const display_type =
    policy.value === LGMCommodityType.DAIRY_CATTLE
      ? toTitleCase(String(type))
      : toTitleCase(type.value);

  const title = `${toTitleCase(policy.value)} ${display_type} ${new Date(
    0,
    monthIdx,
  ).toLocaleString("default", { month: "long" })}`;

  return (
    <div className="card w-100">
      <div className="card-body">
        <div className="ms-0">
          {!isLoading && !isError && plotData && (
            <div style={{ height: 650 }}>
              <Plot
                data={plotData}
                layout={{
                  title: title,
                  // autorange: true,
                  useResizeHandler: true,
                  height: 600,
                  xaxis: {
                    title: "Trading Days Remaining",
                    tickvals: xAxisValues,
                    // ticktext: xAxisValues,
                    range: !isAllSelected && [250, 0],
                    autorange: isAllSelected ? "reversed" : false,
                    zeroline: false,
                  },
                  yaxis: { title: "LGM Margin" },
                  shapes: [
                    {
                      type: "line",
                      x0: DTEChartData.days_to_expiration, // X-axis position of the vertical line
                      x1: DTEChartData.days_to_expiration,
                      y0: Math.min(...plotData.flatMap((d) => d.y)), // Start from the lowest Y value
                      y1: Math.max(...plotData.flatMap((d) => d.y)), // Go up to the highest Y value
                      line: {
                        color: "blue", // Color of the vertical line
                        width: 2, // Thickness of the line
                        dash: "dot", // Makes it a dotted line
                      },
                    },
                  ],
                }}
                config={{
                  displaylogo: false, // This removes the Plotly logo
                }}
                style={{
                  width: "auto",
                  height: "100%",
                  overflow: "auto",
                }}
              />
            </div>
          )}{" "}
          {!isLoading && !isError && !plotData && (
            <div className="text-center m-3" style={{ fontWeight: "500" }}>
              Data Unavailable.
            </div>
          )}
          {isLoading && (
            <div className="text-center m-3" style={{ fontWeight: "500" }}>
              Loading DTE Chart...
            </div>
          )}
          {isError && (
            <div className="text-center m-3" style={{ fontWeight: "500" }}>
              Error Loading DTE Chart...
            </div>
          )}
        </div>
      </div>
      {!isError && !isLoading && plotData && (
        <div
          className="d-flex justify-content-end"
          style={{
            position: "absolute",
            bottom: 20,
            right: 100,
            marginTop: 20,
            paddingBottom: 15,
          }}
        >
          <button
            type="button"
            className="btn btn-secondary me-2"
            onClick={selectAllLines}
          >
            Select All Years
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={deSelectAllLines}
          >
            Deselect All Years
          </button>
        </div>
      )}
    </div>
  );
};

export default LGMDTEChart;
