import React, { useEffect, useState, CSSProperties } from "react";
import DecisionToolContainer from "../../DecisionToolContainer";
import LGMPolicySelector from "../components/LGMPolicySelector";
import LGMMarketingPlan from "../components/LGMMarketingPlan";
import LGMResultTableContainer from "../components/ResultTableContainer";

import "./LGMDecisionTool.scss";
import LGMTypeSelector from "../components/LGMTypeSelector";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import useScoped from "../../../app/utils/hooks/useScoped";
import { resetLGMQuoteResults } from "../features/LGMSlice";
import LGMCattleInputs from "../components/LGMCattleInputs";
import LGMDTEChartHome from "../components/LGMDTEChart/LGMDTEChartHome";

function LGMDecisionTool() {
  const dispatch = useAppDispatch();
  const defaultButtonState = {
    calculator: false,
    chart: false,
  };
  const [activeTab, setActiveTab] = useState(defaultButtonState);
  const { sales_effective_date } = useAppSelector((state) => state.lgm);
  const { scopes } = useAppSelector((state) => state.auth);
  // console.log("scope: ", scopes);
  useEffect(() => {
    // On page load set first tab to active
    //clear quote results on page load as running classvscomponent/strip, navigating to another page then back would try to load thoe reuslts on single
    dispatch(resetLGMQuoteResults());
    setActiveTab((prevState) => ({
      ...prevState,
      calculator: true,
    }));
  }, [dispatch]);
  const setActive = (e) => {
    setActiveTab({
      ...defaultButtonState,
      [e.target.name]: true,
    });
  };

  const dte_chart_permission = useScoped("lgm-dte-charts");
  const LGMDecisionToolCalculator = (
    <div>
      <div className="col my-1" style={{ minWidth: 300 }}>
        <LGMPolicySelector />
      </div>
      <div className="col my-1" style={{ minWidth: 300 }}>
        <LGMTypeSelector />
      </div>
      <div className="col my-1" style={{ minWidth: 300 }}>
        <LGMMarketingPlan />
      </div>
      {new Date(sales_effective_date.value) >= new Date("2024-07-01") && (
        <div className="col my-1" style={{ minWidth: 300 }}>
          <LGMCattleInputs />
        </div>
      )}
      <LGMResultTableContainer />
    </div>
  );

  return (
    <DecisionToolContainer title={"Livestock Gross Margin Decision Tool"}>
      {dte_chart_permission ? (
        <>
          <div className="card-header">
            <ul role="tablist" className="nav nav-tabs card-header-tabs">
              <li className="nav-item dt-tabs-custom">
                <button
                  role="tab"
                  className={`nav-link ${activeTab.calculator && "active"}`}
                  name="calculator"
                  onClick={setActive}
                  style={
                    activeTab.calculator
                      ? { backgroundColor: "white" }
                      : { backgroundColor: "gainsboro", color: "black" }
                  }
                >
                  LGM Decision Tool
                </button>
              </li>
              <li className="nav-item dt-tabs-custom">
                <button
                  role="tab"
                  className={`nav-link ${activeTab.chart && "active"}`}
                  name="chart"
                  onClick={setActive}
                  style={
                    activeTab.chart
                      ? { backgroundColor: "white" }
                      : { backgroundColor: "gainsboro", color: "black" }
                  }
                >
                  LGM DTE Charts
                </button>
              </li>
            </ul>
          </div>
          {activeTab.chart ? <LGMDTEChartHome /> : LGMDecisionToolCalculator}
        </>
      ) : (
        LGMDecisionToolCalculator
      )}
    </DecisionToolContainer>
  );
}

export default LGMDecisionTool;
