import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/redux/hooks";
import { setLGMPolicy, setLGMType } from "../../features/LGMSlice";
import { LGMQuoteType, LGMCommodityType } from "@agdeveloper/domain";

const defaultPolicySelection = {
  [LGMCommodityType.CATTLE]: false,
  [LGMCommodityType.SWINE]: false,
  [LGMCommodityType.DAIRY_CATTLE]: false,
};

const defaultTypePolicyMapping = {
  [LGMCommodityType.CATTLE]: LGMQuoteType.YEARLING_FINISHING,
  [LGMCommodityType.SWINE]: LGMQuoteType.FINISHING,
};

function toTitleCase(str: String) {
  return str
    .replace("_", " ")
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(" ");
}

const LGMDTEPolicySelector = () => {
  const dispatch = useAppDispatch();
  const { policy } = useAppSelector((state) => state.lgm.input);

  const updatePolicy = (policy_name) => {
    if (policy_name === policy.value) {
      return;
    }
    dispatch(setLGMPolicy(new LGMCommodityType(policy_name)));
    dispatch(
      setLGMType(
        policy_name === LGMCommodityType.DAIRY_CATTLE
          ? "MIN_FEED"
          : new LGMQuoteType(defaultTypePolicyMapping[policy_name]),
      ),
    );
  };

  const changePolicy = (e) => {
    const selectedPolicy = e.target.value;
    updatePolicy(selectedPolicy);
  };

  return (
    <div className="card w-100">
      <div className="card-body">
        <label htmlFor="policy-select" style={styles.label}>
          Select Policy:
        </label>
        <select
          id="policy-select"
          value={policy.value}
          onChange={changePolicy}
          style={styles.select}
        >
          {Object.keys(defaultPolicySelection).map((policyKey) => (
            <option key={policyKey} value={policyKey}>
              {toTitleCase(policyKey)}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

const styles = {
  label: {
    marginBottom: "10px",
    fontSize: "16px",
    fontWeight: "bold",
  },
  select: {
    marginLeft: 10,
    padding: "8px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
};

export default LGMDTEPolicySelector;
