import { DTEChartSeries } from "./DTEChartSeries";
export class DTEChartData {
  private dteSeries: DTEChartSeries[];
  days_to_expiration: number;
  colors: string[];
  constructor(dteSeries: DTEChartSeries[] = [], days_to_expiration = 45) {
    this.dteSeries = dteSeries.sort((a, b) => Number(b.year) - Number(a.year));
    this.days_to_expiration = days_to_expiration;
    this.colors = this.getChartColors();
  }

  // Getter and Setter for dteSeries
  getDTESeries() {
    return this.dteSeries;
  }
  getChartColors() {
    // return this.colors;
    const colors = {
      0: { color: "#000000", weight: 1, alpha: 1.0 }, // black
      1: { color: "#0000FF", weight: 1, alpha: 1.0 }, // blue
      2: { color: "#008A00", weight: 1, alpha: 1.0 }, // emerald
      3: { color: "#FF0000", weight: 1, alpha: 1.0 }, // red
      4: { color: "#00BCD4", weight: 1, alpha: 1.0 }, // cyan
      5: { color: "#D80073", weight: 1, alpha: 1.0 }, // magenta
      6: { color: "#FFB74D", weight: 1, alpha: 1.0 }, // orange

      // Gradually increasing transparency for 7 - 16
      7: { color: "#009688", weight: 1, alpha: 0.9 }, // teal
      8: { color: "#FFD700", weight: 1, alpha: 0.85 }, // gold
      9: { color: "#F472D0", weight: 1, alpha: 0.8 }, // pink
      10: { color: "#673AB7", weight: 1, alpha: 0.75 }, // deep purple
      11: { color: "#778899", weight: 1, alpha: 0.7 }, // light slate gray
      12: { color: "#9C27B0", weight: 1, alpha: 0.65 }, // purple
      13: { color: "#6D8764", weight: 1, alpha: 0.6 }, // olive
      14: { color: "#FF5722", weight: 1, alpha: 0.55 }, // deep orange
      15: { color: "#C0392B", weight: 1, alpha: 0.5 }, // blue gray
      16: { color: "#4CAF50", weight: 1, alpha: 0.45 }, // green
    };
    const rgbColors = colors
      ? Object.values(colors).map((color) =>
          DTEChartData.hexToRgba(color.color, color.alpha),
        )
      : [];
    if (rgbColors.length < this.dteSeries.length) {
      rgbColors.push(
        ...DTEChartData.generateColors(
          this.dteSeries.length - rgbColors.length,
        ),
      );
    }
    return rgbColors;
  }

  setDTESeries(dteSeries: any[], days_to_expiration: number) {
    if (
      !Array.isArray(dteSeries) ||
      !dteSeries.every((col) => col instanceof DTEChartSeries)
    ) {
      throw new TypeError(
        "dteSeries must be an array of DTEChartSeries instances",
      );
    }
    if (typeof days_to_expiration !== "number") {
      throw new TypeError("days_to_expiration must be a number");
    }
    this.days_to_expiration = days_to_expiration;
    this.dteSeries = dteSeries;
  }

  // Method to populate the object from JSON
  static fromJson(json) {
    const dteSeries = json.dte_columns?.map(
      (col: any) =>
        new DTEChartSeries(
          col.policy,
          col.quote,
          col.year,
          col.month,
          col.values,
        ),
    );
    return new DTEChartData(dteSeries, json.days_to_expiration);
  }
  private static generateColors(num: number): string[] {
    const colors: string[] = [];
    const seed = 42; // Fixed seed for consistent random values
    const random = DTEChartData.seededRandom(seed);

    for (let i = 0; i < num; i++) {
      let r, g, b;

      // Generate brighter or more faded colors for higher indices (8–19)
      r = Math.floor(150 + random() * 105); // 150–255
      g = Math.floor(150 + random() * 105); // 150–255
      b = Math.floor(150 + random() * 105); // 150–255

      const opacity = (20 - i) / 20; // Decreasing opacity from 1 to 0.05
      colors.push(`rgba(${r}, ${g}, ${b}, ${opacity.toFixed(2)})`);
    }

    return colors;
  }

  // Seeded random number generator for consistent randomness
  private static seededRandom(seed: number): () => number {
    return function () {
      seed = (seed * 9301 + 49297) % 233280;
      return seed / 233280;
    };
  }
  private static hexToRgba = (hex: string, alpha: number) => {
    const r = parseInt(hex.substring(1, 3), 16);
    const g = parseInt(hex.substring(3, 5), 16);
    const b = parseInt(hex.substring(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha.toFixed(2)})`;
  };
}
