import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { setLGMType } from "../features/LGMSlice";
import { LGMQuoteType, LGMCommodityType } from "@agdeveloper/domain";

const LGMTypeSelector = () => {
  const dispatch = useAppDispatch();

  const { policy, type } = useAppSelector((state) => state.lgm.input);

  const [typeName, setTypeName] = useState(type.value);

  const updateType = (type_name) => {
    setTypeName(type_name);
  };

  const changeType = (e) => {
    updateType(String(e.target.name));
  };

  useEffect(() => {
    dispatch(
      setLGMType(
        policy.value !== LGMCommodityType.DAIRY_CATTLE
          ? new LGMQuoteType(typeName)
          : new LGMQuoteType(LGMQuoteType.NO_TYPE_SPECIFIED),
      ),
    );
  }, [typeName, dispatch]);

  return (
    <>
      {policy.value === LGMCommodityType.CATTLE && (
        <div className="card w-100">
          <div className="card-body">
            <h5 className="card-title">Type Selection</h5>
            <p className="card-text">Please select your insurance type.</p>
            <form>
              <div className="form-group">
                <div>
                  <input
                    type="radio"
                    style={{ marginRight: 10 }}
                    id={LGMQuoteType.YEARLING_FINISHING}
                    name={LGMQuoteType.YEARLING_FINISHING}
                    checked={type.value === LGMQuoteType.YEARLING_FINISHING}
                    onChange={(e) => changeType(e)}
                  />
                  <label
                    htmlFor={LGMQuoteType.YEARLING_FINISHING}
                    className="form-check-label"
                  >
                    Yearling
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    style={{ marginRight: 10 }}
                    id={LGMQuoteType.CALF_FINISHING}
                    name={LGMQuoteType.CALF_FINISHING}
                    checked={type.value === LGMQuoteType.CALF_FINISHING}
                    onChange={(e) => changeType(e)}
                  />
                  <label
                    htmlFor={LGMQuoteType.CALF_FINISHING}
                    className="form-check-label"
                  >
                    Calf
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {policy.value === LGMCommodityType.SWINE && (
        <div className="card w-100">
          <div className="card-body">
            <h5 className="card-title">Type Selection</h5>
            <p className="card-text">Please select your insurance type.</p>
            <form>
              <div className="form-group">
                <div>
                  <input
                    type="radio"
                    style={{ marginRight: 10 }}
                    id={LGMQuoteType.FINISHING}
                    name={LGMQuoteType.FINISHING}
                    checked={type.value === LGMQuoteType.FINISHING}
                    onChange={(e) => changeType(e)}
                  />
                  <label
                    htmlFor={LGMQuoteType.FINISHING}
                    className="form-check-label"
                  >
                    Feeder Pig-Finishing
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    style={{ marginRight: 10 }}
                    id={LGMQuoteType.FARROW_TO_FINISH}
                    name={LGMQuoteType.FARROW_TO_FINISH}
                    checked={type.value === LGMQuoteType.FARROW_TO_FINISH}
                    onChange={(e) => changeType(e)}
                  />
                  <label
                    htmlFor={LGMQuoteType.FARROW_TO_FINISH}
                    className="form-check-label"
                  >
                    Farrow-To-Finish
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    style={{ marginRight: 10 }}
                    id={LGMQuoteType.SEW_PIG_FINISHING}
                    name={LGMQuoteType.SEW_PIG_FINISHING}
                    checked={type.value === LGMQuoteType.SEW_PIG_FINISHING}
                    onChange={(e) => changeType(e)}
                  />
                  <label
                    htmlFor={LGMQuoteType.SEW_PIG_FINISHING}
                    className="form-check-label"
                  >
                    SEW
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default LGMTypeSelector;
